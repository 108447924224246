<template>
  <div class="box">
    <!-- vip打折活动模块 -->
    <div class="vip-box">
      <img src="@/assets/img/diamond.png" alt="" class="vip-img" />
      <!-- 右侧内容区域 -->
      <div class="content">
        <p class="title">成为会员立享9.6折</p>
        <p class="subtitle">
          会员支付可享9.6折，VIP客户专属客服，不可同时和优惠券使用。
        </p>
      </div>
    </div>

    <!-- 充值价钱 -->
    <div class="vip-price">
      <div class="title">
        充值VIP
      </div>
      <div class="price-box">
        <div
          class="price-item"
          v-for="(item, index) in rechargeVip"
          :key="index"
          :class="rechargeIndex == index ? 'curBg' : ''"
          @click="rechargeIndex = index"
        >
          <span v-if="item.recommend == 1" class="tag">推荐</span>
          <p class="card-type">{{ item.vipName }}</p>
          <p class="price">{{ item.vipPrice }}元</p>
          <p class="day">{{ item.validDate }}天</p>
          <p class="old-price">{{ item.vipOriginalPrice }}元</p>
        </div>
      </div>
    </div>

    <!-- 支付方法 -->
    <div class="paymentMethod">
      <div class="title">
        支付方式
      </div>
      <div class="method-box">
        <div class="img">
          <img src="@/assets/img/home/wechat.svg" alt="" />
          <span>微信支付</span>
        </div>
        <div>
          <img src="@/assets/img/choose-active.svg" alt="" />
        </div>
      </div>
      <div class="submitPay" @click="submitPay">
        确认开通会员
      </div>
      <!-- 协议链接 -->
      <p class="vip-agreement">
        <a href="http://lsdj-img.lanshididu.com/agreement/%E4%BC%9A%E5%91%98%E5%8D%8F%E8%AE%AE.html">会员服务协议</a>
      </p>
    </div>

    <!-- 权益 -->
    <div class="vip-equity">
      <div class="title">
        会员权益
      </div>
      <div class="equity-box">
        <div
          class="equity-item"
          v-for="(item, index) in vipEquity"
          :key="index"
        >
          <img :src="item.img" alt="" class="img" />
          <div class="content">
            <p class="text">{{ item.title }}</p>
            <p class="desc">{{ item.subtitle }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homeVipCenter, payVip } from "@/api/index";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      rechargeIndex: -1,
      rechargeVip: [],
      canClick: true,
      vipEquity: [
        {
          img: require("@/assets/img/home/vip_icon3.svg"),
          title: "会员支付9.6折",
          subtitle: "不可同时叠加优惠券使用"
        },
        {
          img: require("@/assets/img/home/vip_icon2.svg"),
          title: "专属客服",
          subtitle: "24小时专属客服服务"
        },
        {
          img: require("@/assets/img/home/vip_icon1.svg"),
          title: "订单退款秒到账",
          subtitle: "VIP订单退款秒到账"
        },
        {
          img: require("@/assets/img/home/vip_icon4.svg"),
          title: "优享活动",
          subtitle: "专属VIP会员活动优惠多多"
        }
      ]
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      homeVipCenter().then(res => {
        if (res.code == 10000) {
          this.rechargeVip = res.data;
        }
      });
    },
    submitPay() {
      if (!this.canClick) {
        this.$toast("请勿重复点击");
        return;
      }

      if (this.rechargeIndex == -1) {
        this.$toast("请选择充值套餐");
        return;
      }

      payVip({
        vipInfoId: this.rechargeVip[this.rechargeIndex].id
      })
        .then(res => {
          console.log(res);
          if (res.code == 10000) {
            this.wxPay(res.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    wxPay(e) {
      wx.chooseWXPay({
        appId: e.appId,
        timestamp: e.timeStamp,
        nonceStr: e.nonceStr,
        package: e.prepayId,
        paySign: e.paySign,
        signType: "RSA",
        success: res => {
          this.$toast("支付成功");

          this.canClick = true;
          console.log(res);
          // this.$router.go(-1);
        },
        cencel: res => {
          this.canClick = true;
          this.$toast("取消支付");
        },
        fail: err => {
          this.canClick = true;
          this.$toast("支付失败");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/base.less";

.title() {
  font-size: 0.5rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
}

// 盒子
.box {
  .paddingBox();
}

.vip-box {
  width: 100%;
  height: 2.933rem;
  background-image: url("../../assets/img/bg-vip.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
  padding: 0.6rem 0.38rem;

  .vip-img {
    position: absolute;
    left: 0%;
    top: -0.55rem;
  }

  .content {
    margin-left: 3.2rem;

    .title {
      font-size: 0.45rem;
      color: white;
    }

    .subtitle {
      font-size: 0.3rem;
      color: #b3b3b3;
      margin-top: 0.2rem;
      line-height: 0.5rem;
    }
  }
}

.vip-price {
  margin: 0.5rem 0;
  .title {
    .title();
  }
  .price-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
    .price-item {
      height: 4rem;
      background-color: #fff;
      border-radius: 0.1rem;
      box-sizing: border-box;
      padding: 0.3rem 0.2rem;
      display: flex;
      flex-direction: column;
      border: 1px solid #efefef;
      align-items: center;
      justify-content: space-around;
      position: relative;
      border-radius: 0.2rem;
      .tag {
        position: absolute;
        right: 0%;
        top: 0;
        background-color: @REDCOLOR;
        color: white;
        padding: 0.08rem 0.2rem;
        font-size: 0.2rem;
        border-radius: 0 0.2rem 0 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }

      .card-type {
        font-size: 0.4rem;
        color: #333333;
      }

      .price {
        font-size: 0.5rem;
        color: @REDCOLOR;
        font-weight: bold;
      }

      .day {
        font-size: 0.35rem;
        color: @REDCOLOR;
      }

      .old-price {
        font-size: 0.4rem;
        // 划掉的钱
        text-decoration: line-through;
      }
    }
    .curBg {
      border: 1px solid @REDCOLOR;
      background-color: #fdedef;
    }
  }
}

.paymentMethod {
  .title {
    .title();
  }
  .method-box {
    .flex-row-between();
    .img {
      .flex-row-between();
      img {
        margin-right: 0.2rem;
      }
    }
  }

  .submitPay {
    background-color: @REDCOLOR;
    color: white;
    width: 100%;
    border-radius: 1rem;
    text-align: center;
    padding: 0.5rem 0;
    font-size: 0.4rem;
    margin: 0.5rem 0;
  }

  .vip-agreement {
    text-align: right;
    a {
      color: @REDCOLOR;
      font-size: 0.35rem;
    }
  }
}

.vip-equity {
  margin-top: 0.5rem;
  .title {
    .title();
  }

  .equity-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // 网格布局横向间距
    grid-row-gap: 0.3rem;
    // 网格布局纵向间距
    grid-column-gap: 0.25rem;
    .equity-item {
      .flex-row-between();
      background-color: #fdedef;
      padding: 0.2rem 0.2rem;
      border-radius: 0.2rem;
      height: 1.8rem;
      img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.2rem;
      }

      .content {
        .text {
          margin-bottom: 0.2rem;
          font-size: 0.4rem;
          color: #333333;
        }
        .desc {
          color: #aba7a8;
          font-size: 0.33rem;
        }
      }
    }
  }
}
</style>
